import * as React from 'react';
import Container from "@mui/material/Container";
import ReactMarkdown from 'react-markdown';
import {Prism as SyntaxHighlighter} from 'react-syntax-highlighter'
import {vscDarkPlus, oneLight} from 'react-syntax-highlighter/dist/esm/styles/prism'
import SEO from "../../components/SEO";
import {detectColorMode} from "../../colorMode";
import {useState} from "react";

const doc = `
# Simple CI+CD Pipeline for a Golang app

## Introduction

Implementing a Continuous Integration (CI) pipeline will make you confident about the changes you made in your app, and the Continuous Deployment (CD) process will make you deliver fast.
A properly set up CI+CD Pipeline complements perfectly and brings you speed and confidence in your work. That's why it's important to set it up from the beginning of your project.

## Prerequisites

- **GitHub Actions**: You can use GitHub Actions to build and deploy your app.
- **Server**: You need a server to deploy your app. You can use a VPS like Hetzner, DigitalOcean, or AWS.
- [Supervisord](http://supervisord.org/): To manage app's lifecycle.
- **Test suite**: usually, CI pipelines run tests, code quality checks, and other validations. At the very beginning, you can start with a simple test suite.

## Example

Use only the [official actions](https://github.com/actions). This will make your pipeline reliable and secure.
For example: you can easily use SSH and SCP instead of using a third-party action to deploy your app.

Create a file named **.github/workflows/cicd.yml** in your repository with the following content:

~~~yaml
name: CI+CD
on:
  # Allow manual trigger
  workflow_dispatch: ~
  # Trigger on push to main branch
  push:
    branches:
      - main

jobs:
  test_and_build:
    name: Test and Build
    runs-on: ubuntu-latest
    timeout-minutes: 10
    steps:
      # Checkout the code
      - uses: actions/checkout@v4
      # Setup Go. You can use your language's setup action
      - uses: actions/setup-go@v5
        with:
          go-version: '1.23'
          cache-dependency-path: go.sum

      # App setup step, download modules
      - name: Download modules
        working-directory: server
        run: go mod download

      # Run tests
      - name: Run tests
        working-directory: server
        run: make test

      # Build the app
      - name: Build
        env:
          GOOS: linux
          GOARCH: arm64
        run: |
          go build -o build/app ./cmd/app

      # If your app loads configuration from a file, write it here
      - name: Write config file
        run: |
          echo "ENV=prod" > .env
          echo "DB_DSN=\${{ secrets.DB_DSN }}" >> .env

      # Upload build artifacts
      - name: Upload build artifacts
        uses: actions/upload-artifact@v4
        with:
          name: my-app-build
          overwrite: 'true'
          include-hidden-files: 'true'
          if-no-files-found: 'error'
          path: |
            build/
            .env

  deploy:
    name: Deploy server
    needs: test_and_build
    runs-on: ubuntu-latest
    strategy:
      matrix:
        # Add more server hosts as needed
        server_host: ["12.34.56.78",]
    env:
      SERVER_HOST: \${{ matrix.server_host }}
    steps:
      - name: Download build artifacts
        uses: actions/download-artifact@v4
        with:
          name: my-app-build
          path: myapp/

      # Do this to avoid SSH key prompt
      - name: Add SSH Key to Known Hosts
        run: |
          mkdir -p ~/.ssh
          ssh-keyscan \${{ env.SERVER_HOST }} >> ~/.ssh/known_hosts

      - name: Deploy
        env:
          # Server's private key
          PRIVATE_KEY: \${{ secrets.SERVER_PRIVATE_KEY }}
          # Target directory on the server where the app will be deployed
          TARGET_DIR: /home/app/
        run: |
          echo "\${{ env.PRIVATE_KEY }}" > private_key.pem
          chmod 600 private_key.pem
          ssh -i private_key.pem user@\${{ env.SERVER_HOST }} "sudo supervisorctl stop all"
          scp -i private_key.pem -r myapp/build/* myapp/.env user@\${{ env.SERVER_HOST }}:\${{ env.TARGET_DIR }}
          ssh -i private_key.pem user@\${{ env.SERVER_HOST }} "sudo supervisorctl start all"
          rm private_key.pem
~~~

## Explanation

The pipeline consists of two jobs:
- **test_and_build**: This job runs on every push to the main branch. It checks out the code, downloads the Go modules, runs the tests, builds the app, and uploads the build artifacts.
- **deploy**: This job runs only if the previous job succeeds. It downloads the build artifacts, adds the server's SSH key to the known hosts, and deploys the app to the server.
`;

export default function SimpleCICDPipeline() {
  const [mode, setMode] = useState(detectColorMode());

  return (
    <>
      <SEO title="ObsLabs | Simple CI+CD Pipeline for a Golang app"  url="/blog/simple-ci-cd-pipeline" description="Simple CI+CD Pipeline for a Golang app"/>
      <Container
        id="top"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          pt: {xs: 8, sm: 12},
          pb: {xs: 8, sm: 12},
        }}
      >
        <ReactMarkdown components={{
          code({node, inline, className, children, ...props}) {
            return <SyntaxHighlighter style={
              mode === 'dark' ? vscDarkPlus : oneLight
            } language={"yaml"} children={String(children).replace(/\n$/, '')} {...props} />
          }
        }}>
          {doc}
        </ReactMarkdown>
      </Container>
    </>
  );
}
