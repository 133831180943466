import * as React from 'react';
import Container from "@mui/material/Container";
import SEO from "../components/SEO";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import {Link as RouterLink} from "react-router-dom";

export default function Blog() {
  return (
    <>
      <SEO title="ObsLabs | Blog" url={"/blog"} description={"Blog posts from ObsLabs."}/>
      <Container
        id="top"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          pt: {xs: 8, sm: 12},
          pb: {xs: 8, sm: 12},
        }}
      >
        <Typography variant="h1" sx={{mb: 4}}>
          Blog posts
        </Typography>
        <List>
          <ListItem>
            <RouterLink to="/blog/simple-ci-cd-pipeline" style={{textDecoration: 'none'}}>
              <ListItemText primary="Simple CI+CD Pipeline for a Golang app"
                            sx={{cursor: 'pointer'}}
              />
            </RouterLink>
          </ListItem>
          <ListItem>
            <RouterLink to="/blog/how-to-setup-a-website-or-api-monitoring" style={{textDecoration: 'none'}}>
            <ListItemText primary="How to: setup a Website or API monitoring"
                          sx={{cursor: 'pointer'}}
            />
            </RouterLink>
          </ListItem>
          <ListItem>
            <RouterLink to="/blog/how-to-setup-a-cron-monitoring" style={{textDecoration: 'none'}}>
            <ListItemText primary="How to: setup a CRON job monitoring"
                          sx={{cursor: 'pointer'}}
            />
            </RouterLink>
          </ListItem>
          <ListItem>
            <RouterLink to="/blog/tech-stack" style={{textDecoration: 'none'}}>
            <ListItemText primary="ObsLabs Tech Stack"
                          sx={{cursor: 'pointer'}}
            />
            </RouterLink>
          </ListItem>
        </List>
      </Container>
    </>
  );
}
