import {createBrowserRouter} from "react-router-dom";
import AdminLayout from "./admin/AdminLayout";
import * as React from "react";
import Dashboard from "./admin/Dashboard";
import SignIn from "./admin/Signin";
import SignOut from "./admin/Signout";
import {Probes} from "./admin/probes/Probes";
import {Channels} from "./admin/channels/Channels";
import {ChannelForm} from "./admin/channels/ChannelForm";
import {getProbeLoader, ProbeForm} from "./admin/probes/ProbeForm";
import {Account} from "./admin/account/Account";
import {ProjectForm} from "./admin/projects/ProjectForm";
import Projects from "./admin/projects/Projects";
import {
  organizationLoader,
  withSelectedOrganizationsLoader,
  withSelectedOrganizationAndProjectLoader,
  organizationsLoader,
  projectsLoader,
  channelLoader,
  channelsLoader,
  projectLoader,
  listProbesLoader, listInvitationsLoader, listIntegrationsLoader,
  accountLoader, apiKeysLoader, listProbeStatusChangesLoader,
} from "./admin/loaders/loaders";
import {OrganizationForm} from "./admin/organizations/OrganizationForm";
import OrganizationMembers from "./admin/organizations/OrganizationMembers";
import ProjectMembers from "./admin/projects/ProjectMembers";
import Homepage from "./landing/Homepage";
import Landing from "./landing/Landing";
import TermsOfService from "./landing/TermsOfService";
import DPA from "./landing/DPA";
import PrivacyPolicy from "./landing/PrivacyPolicy";
import ApiDocs from "./landing/ApiDocs";
import Invitations from "./admin/invitations/Invitations";
import Join from "./admin/invitations/Join";
import {AcceptInvitation} from "./api/methods";
import {Integrations} from "./admin/integrations/Integrations";
import {OrganizationSubscription} from "./admin/organizations/OrganizationSubscription";
import Subprocessors from "./landing/Subprocessors";
import {ProbeView} from "./admin/probes/ProbeView";
import Blog from "./landing/Blog";
import ObsLabsTechStack from "./landing/blog/ObsLabsTechStack";
import HowToSetupCronMonitoring from "./landing/blog/HowToSetupCronMonitoring";
import HowToSetupWebsiteMonitoring from "./landing/blog/HowToSetupWebsiteMonitoring";
import SimpleCICDPipeline from "./landing/blog/SimpleCICDPipeline";

export const routes = [
  {
    path: "/",
    element: <Landing inner={<Homepage/>}/>,
  },
  {
    path: "/blog",
    element: <Landing inner={<Blog />} />,
  },
  {
    path: "/blog/tech-stack",
    element: <Landing inner={<ObsLabsTechStack />} />,
  },
  {
    path: "/blog/how-to-setup-a-cron-monitoring",
    element: <Landing inner={<HowToSetupCronMonitoring />} />,
  },
  {
    path: "/blog/how-to-setup-a-website-or-api-monitoring",
    element: <Landing inner={<HowToSetupWebsiteMonitoring />} />,
  },
  {
    path: "/blog/simple-ci-cd-pipeline",
    element: <Landing inner={<SimpleCICDPipeline />} />,
  },
  {
    path: "/terms-of-service",
    element: <Landing inner={<TermsOfService/>}/>,
  },
  {
    path: "/docs",
    element: <ApiDocs/>,
  },
  {
    path: "/dpa",
    element: <Landing inner={<DPA/>}/>,
  },
  {
    path: "/subprocessors",
    element: <Landing inner={<Subprocessors/>}/>,
  },
  {
    path: "/privacy-policy",
    element: <Landing inner={<PrivacyPolicy/>}/>,
  },
  {
    path: "/admin/sign-in",
    element: <SignIn/>,
  },
  {
    path: "/admin/sign-out",
    element: <SignOut/>,
  },
  {
    path: "/admin",
    element: <Dashboard/>,
    loader: async () => {
      const [user, organizations] = await Promise.all([accountLoader(), organizationsLoader()]);
      return {
        user: user.user,
        organizations: organizations.organizations,
      };
    },
  },

  // ORGANIZATIONS
  {
    path: "/admin/organizations/new",
    element: <AdminLayout isAccountView={true} inner={<OrganizationForm/>}/>,
    loader: async () => {
      return {};
    },
  },
  {
    path: "/admin/organizations/:organizationID/members",
    element: <AdminLayout isAccountView={true} inner={<OrganizationMembers/>}/>,
    loader: async ({params}) => {
      const [organization, user] = await Promise.all([organizationLoader({params}), accountLoader()]);
      return {
        organization: organization.organization,
        user: user.user,
      };
    },
  },
  {
    path: "/admin/organizations/:organizationID/members/invitations",
    element: <AdminLayout isAccountView={true} inner={<Invitations/>}/>,
    loader: async ({params}) => {
      const [organization, user, invitations] = await Promise.all([organizationLoader({params}), accountLoader(), listInvitationsLoader({params})]);
      return {
        organization: organization.organization,
        user: user.user,
        invitations: invitations.invitations,
      };
    },
  },
  {
    path: "/admin/join/:organizationID/:invitationID/:secretKey",
    element: <AdminLayout isAccountView={true} inner={<Join/>}/>,
    loader: async ({params}) => {
      const [code,] = await AcceptInvitation(params.organizationID, params.invitationID, {secret_key: params.secretKey});
      if (code !== 204) {
        throw new Error("Failed to accept invitation");
      }

      const [user, organization] = await Promise.all([accountLoader(), organizationLoader({params})]);
      return {
        user: user.user,
        organization: organization.organization,
      };
    },
  },
  {
    path: "/admin/organizations/:organizationID/projects/:projectID/integrations",
    element: <AdminLayout inner={<Integrations/>}/>,
    loader: async ({params}) => {
      const [data, user, integrations] = await Promise.all([withSelectedOrganizationAndProjectLoader({params}), accountLoader(), listIntegrationsLoader({params})]);
      return {
        ...data,
        user: user.user,
        integrations: integrations.integrations,
      };
    },
  },
  {
    path: "/admin/organizations/:organizationID/subscription",
    element: <AdminLayout isAccountView={true} inner={<OrganizationSubscription/>}/>,
    loader: async ({params}) => {
      const [organization, user] = await Promise.all([organizationLoader({params}), accountLoader()]);
      return {
        organization: organization.organization,
        user: user.user,
      };
    },
  },
  {
    path: "/admin/organizations/:organizationID/subscription/success",
    element: <AdminLayout isAccountView={true} inner={<OrganizationSubscription/>}/>,
    loader: async ({params}) => {
      const [organization, user] = await Promise.all([organizationLoader({params}), accountLoader()]);
      return {
        organization: organization.organization,
        user: user.user,
      };
    },
  },

  // PROJECTS
  {
    path: "/admin/organizations/:organizationID/projects/new",
    element: <AdminLayout isAccountView={true} inner={<ProjectForm/>}/>,
    loader: async ({params}) => {
      const data = await withSelectedOrganizationsLoader({params});
      return {
        selectedOrganization: data.selectedOrganization,
        organizations: data.organizations,
      };
    },
  },
  {
    path: "/admin/organizations/:organizationID/projects",
    element: <AdminLayout isAccountView={true} inner={<Projects/>}/>,
    loader: async ({params}) => {
      const [projects, organization, user] = await Promise.all([projectsLoader({params}), organizationLoader({params}), accountLoader()]);
      return {
        organization: organization.organization,
        projects: projects.projects,
        user: user.user,
      };
    },
  },
  {
    path: "/admin/organizations/:organizationID/projects/:projectID/members",
    element: <AdminLayout isAccountView={true} inner={<ProjectMembers/>}/>,
    loader: async ({params}) => {
      const [project, organization, user] = await Promise.all([projectLoader({params}), organizationLoader({params}), accountLoader()]);
      return {
        project: project.project,
        organization: organization.organization,
        user: user.user,
      };
    },
  },

  // CHANNELS
  {
    path: "/admin/organizations/:organizationID/projects/:projectID/channels",
    element: <AdminLayout inner={<Channels/>}/>,
    loader: async ({params}) => {
      const [projects, channels, integrations] = await Promise.all([withSelectedOrganizationAndProjectLoader({params}), channelsLoader({params}), listIntegrationsLoader({params})]);
      return {
        ...projects,
        ...channels,
        ...integrations,
      };
    },
  },
  {
    path: "/admin/organizations/:organizationID/projects/:projectID/channels/new",
    element: <AdminLayout inner={<ChannelForm/>}/>,
    loader: async ({params}) => {
    const [projects, integrations] = await Promise.all([withSelectedOrganizationAndProjectLoader({params}), listIntegrationsLoader({params})]);
      return {
        ...projects,
        ...integrations,
        ...{
          channel: {
            id: null,
            name: "New channel",
            project_id: params.projectID,
            emails: [],
            webhooks: [],
            slacks: [],
            smss: [],
          }
        },
      };
    },
  },
  {
    path: "/admin/organizations/:organizationID/projects/:projectID/channels/:channelID/edit",
    element: <AdminLayout inner={<ChannelForm/>}/>,
    loader: async ({params}) => {
      const [projects, channel, integrations] = await Promise.all([withSelectedOrganizationAndProjectLoader({params}), channelLoader({params}), listIntegrationsLoader({params})]);
      return {
        ...projects,
        ...integrations,
        ...{
          channel: channel.channel,
        }
      };
    },
  },

  // PROBES
  {
    path: "/admin/organizations/:organizationID/projects/:projectID/probes",
    element: <AdminLayout inner={<Probes/>}/>,
    loader: async ({params}) => {
      const [projects, probes] = await Promise.all([withSelectedOrganizationAndProjectLoader({params}), listProbesLoader({params})]);
      return {
        ...projects,
        ...probes,
      };
    },
  },
  {
    path: "/admin/organizations/:organizationID/projects/:projectID/probes/new",
    element: <AdminLayout inner={<ProbeForm/>}/>,
    loader: async ({params}) => {
      const [projects, channels] = await Promise.all([withSelectedOrganizationAndProjectLoader({params}), channelsLoader({params})]);
      return {
        ...projects,
        ...channels,
        ...{
          probe: {
            id: null,
            name: "New probe",
            url: "",
            project_id: params.projectID,
            type: "push",
            channels: null,
            schedule: {
              type: "periodic",
              period_seconds: 60,
              failure_threshold: 1,
              success_threshold: 1,
            }
          }
        },
      };
    },
  },
  {
    path: "/admin/organizations/:organizationID/projects/:projectID/probes/:probeID/edit",
    element: <AdminLayout inner={<ProbeForm/>}/>,
    loader: async ({params}) => {
      const [projects, channels, probe] = await Promise.all([withSelectedOrganizationAndProjectLoader({params}), channelsLoader({params}), getProbeLoader({params})]);
      return {
        ...projects,
        ...channels,
        ...{
          probe: probe.probe,
        },
      };
    },
  },
  {
    path: "/admin/organizations/:organizationID/projects/:projectID/probes/:probeID/view",
    element: <AdminLayout inner={<ProbeView/>}/>,
    loader: async ({params}) => {
      const [projects, probe, probeStatusChanges] = await Promise.all([withSelectedOrganizationAndProjectLoader({params}), getProbeLoader({params}), listProbeStatusChangesLoader({params})]);
      return {
        ...projects,
        ...probe,
        ...probeStatusChanges,
      };
    },
  },

  // ACCOUNT
  {
    path: "/admin/account",
    element: <AdminLayout isAccountView={true} inner={<Account/>}/>,
    loader: async ({params}) => {
      const [organizationsLoaderData, user, apiKeys] = await Promise.all([organizationsLoader({params}), accountLoader(), apiKeysLoader()]);
      return {
        organizations: organizationsLoaderData.organizations,
        user: user.user,
        apiKeys: apiKeys.api_keys,
      };
    }
  },
];

const router = createBrowserRouter(routes);

export default router;
